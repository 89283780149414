
import { motion } from "framer-motion";
import foto from '../img/potrait2.png';
import layer from '../img/Rechteck.png';
// Import icons
import styled from 'styled-components'
//styles
import { About, Description, Image } from '../styles'
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'

const VitaSection = () => {
    const [element, controls] = useScroll();
    return (
        <Vita variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <h2>
                Meine<Divider> Vita</Divider>
            </h2>

            <Container>
                <h3>Home & Family Stefanie Zinn</h3>

                <div>
                    <img src={foto} alt="Potrait Stefanie Zinn" />
                    <article>
                        <p>
                            Meine Name ist Stefanie Zinn. Ich bin staatlich geprüfte Wirtschafterin, arbeite seit 10 Jahren im hauswirtschaftlichen Bereich und seit 4 Jahren bin ich als Hauswirtschaftsleitung tätig.

                        </p>
                        <p>
                            Heutzutage jemanden für die Angehörigen zu finden, der zuverlässig sowie vertrauensvoll und auch noch einfühlsam ist…  das gibt es leider kaum noch. Aus diesem Grunde, habe ich mich dazu entschlossen mich selbstständig zu machen, um Menschen im Alltag zu helfen.

                        </p>
                        <p>
                            Ich finde mit Ihnen zusammen die optimale Lösung.

                        </p>
                        <h4>
                            -Stefanie Zinn
                        </h4>
                    </article>
                </div>
            </Container>




        </Vita>
    );
};


const Vita = styled(motion.div)`

display: flex;
flex-direction: column;
width: 80%;
margin: 8rem auto;
@media (max-width: 1095px){
    margin: 4rem auto;
}

h2{
    padding-bottom: 5rem;
    text-decoration: underline;
    text-decoration-color: #666;
    text-decoration-thickness: 1px;
   text-underline-offset: 15px;
   text-align: right;
   font-size: 3rem;
   @media (max-width: 1095px){
    text-align: center;
    span{
        margin:0;
    }
}
   
}

`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
@media (max-width: 1095px){
   
    h3{
        text-align: center;
    }
}

p{
    color:#707070;
    
}
@media (max-width: 2204px){
      p{
          font-size:1.2rem;
          text-align:justify;
      }
      }
div{
display: flex;
margin: 2rem auto;
}
div{
    border-radius: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 1095px){
   
   display:flex;
   flex-direction:column;
}
    
    
}
article{
    
    margin: 8rem;
    @media (max-width: 2204px){
        margin: 4rem;
      };
      @media (max-width: 1287px){
        margin: 1.5rem;
      }
      
      @media (max-width: 1095px){
   p{
    font-size: 1.2rem;
   width: 90%;
   margin:0 auto;
   }
   h4{
    font-size: 1.5rem;
   width: 90%;
   margin:2rem auto;
   }
   
}
}
img{
    width: 100%;
    border-radius: 3rem 0 0 3rem;
    @media (max-width: 1095px){
   
        border-radius: 3rem 3rem 0 0;
}
    
}
`;

const Divider = styled.span`
margin:0;

`;

export default VitaSection;