import styled from 'styled-components'
import { motion } from "framer-motion";

export const About = styled(motion.div)` 
position: relative;
min-width: height 90vh;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0rem 0rem 6rem 10rem;
color: #42495B;
@media (max-width: 800px){
    display:block;
    padding: 0rem 0rem 6rem 0rem;
    }

`;

export const Description = styled.div`
flex: 0.5;
padding-right: 5rem;
z-index: 2;
h2{
    font-weight: lighter;
}
@media (max-width: 1300px){
        padding:0;
        button{
            margin: 2rem 0rem 5rem 0rem; 
        }
    }
`;

export const Image = styled.div`
flex: 2;
overflow: hidden;
z-index: -3;
img{
    width: 120%;
    height: 100vh;
    object-fit: cover;
    @media (max-width: 1075px){
        width: 105%;
       
 };
 @media (max-width: 800px){
        width: 100%;
        margin:0;
        padding:0;
       
 };
}
`;

export const Hide = styled.div`
overflow: hidden;
`;