
import styled from 'styled-components'
import { About } from '../styles'
import Toggle from './Toggle';
import home2 from '../img/home2.jpeg';
import layer from '../img/layer2.png';
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";


const FaqSection = () => {
    const [element, controls] = useScroll();
    return (
        <Faq variants={scrollReveal} ref={element} initial="hidden" animate={controls}>
            <Container>

                <h2>
                    Wege der<Divider> Finanzierung</Divider>
                </h2>

                <Cards>

                    <article>
                        <div>

                            <h3>
                                Mit Herz, Liebe zum Detail...
                </h3>
                            <h4>Privatkunden: Steuerlich absetzbar für Ihr Wohlbefinden</h4>
                            <p>
                                Mit Herz und Liebe zum Detail erbringen wir unsere Dienstleistungen, während Sie sich um Kosten keine Sorgen machen müssen. Privatleistungen sind bei uns steuerlich absetzbar, sodass Sie finanziell optimal profitieren können.
                                Darüber hinaus legen wir besonderen Wert auf generationsübergreifende Betreuung, umfassende gesunde Ernährung und die Reduzierung von Alltagsstress. Unsere Dienstleistungen sind darauf ausgerichtet, nicht nur Ihre Bedürfnisse zu erfüllen, sondern ein ganzheitliches Wohlbefinden zu fördern.
                                </p>


                        </div>
                    </article>

                    <Image2>

                        <img className="layer" src={layer} alt="übergang" />
                        <img className="home" src={home2} alt="zuhause sich wohl fühlen" />

                    </Image2>

                </Cards>
            </Container>


        </Faq >)
};

const Faq = styled(motion.div)`
position: relative;
background-color:#FAF4F4;
z-index:-100;
padding: 1rem 0;



h2{
    padding-bottom: 5rem;
    text-decoration: underline;
    text-decoration-color: #666;
    text-decoration-thickness: 1px;
   text-underline-offset: 15px;
   text-align: left;
   font-size: 3rem;
   @media (max-width: 1095px){
    text-align: center;
    font-size: 2rem;
    
    span{
        margin:0;
    }
}
}



`;

const Container = styled.section`
display: flex;
flex-direction: column;
width: 80%;
margin: 8rem auto;
@media (max-width: 1095px){
        width:100%;
        margin: 2rem auto;
}
`;

const Cards = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
@media (max-width: 1095px){
        display:flex;
        flex-direction:column;
}

article{
    display:flex;
    flex-direction: column;
    justify-content:center;
    padding-left:10rem;
    @media (max-width: 1095px){
        padding-left:0rem;
        align-items:center;
}
   
    h3{
    width: 100%;
    @media (max-width: 2070px){
        width: 120%;
        margin-bottom: .4rem;
        @media (max-width: 1095px){
            width: 80%;
            justify-content:center;
            margin: 2rem auto;
}
    }
}
h4{
    color:#000;
    font-size:1.3rem;
    padding: 1.5rem 0rem 1rem;
      width: 100%;
      @media (max-width: 1095px){
            width: 80%;
            font-size: 1.3rem;
            margin: 2rem auto;
            
}
}
   p{
    color:#707070;
    font-size:1rem;
    padding: 1.5rem 0rem 1.5rem;
      width: 90%;
      @media (max-width: 2070px){
        width: 120%;
        padding: .8rem 0rem;
        @media (max-width: 1095px){
            width: 80%;
            font-size: 1.2rem;
            margin: 2rem auto;
            
}
    }
   }
   h5{
       font-size: 1.2rem;
   }
   
  
}
`;

const Image2 = styled.div`
position: relative;
.layer{
    width: 180%;
    
    position: absolute;
    z-index:-1;
    right:20%;
    top: 15%;
    @media (max-width: 1095px){
    display:none;
}
}
.home{
    position: relative;
   
    width: 70%;
    border-radius: 3rem;
    z-index:-2;
    left:30%;
    @media (max-width: 1095px){
        width: 80%;
      left:10%;
}
}

`;
const Divider = styled.span`
margin:0;

`;

export default FaqSection;