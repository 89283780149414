
//Global Style
import GlobalStyle from './components/GlobalStyle';
import Nav from './components/Nav';
//Import Pages
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import OurWork from './pages/OurWork';

// import ScrollTop from './components/ScrollTop'
//Router
import { Switch, Route, useLocation } from 'react-router-dom';
import ProjektDetail from './pages/ProjektDetail';
import TermineBuchen from './pages/TermineBuchen';
//Animation
import { AnimatePresence } from "framer-motion"
import Footer from './components/Footer';
import Navbar from './components/Navbar';




function App() {
  const location = useLocation()
  //console.log('hallo', location.pathname)
  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <AboutUs />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/work" exact>
            <OurWork />
          </Route>
          <Route path="/karriere" >
            <ProjektDetail />
          </Route>
          <Route path="/termine" exact >
            <TermineBuchen />
          </Route>



        </Switch>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
