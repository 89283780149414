
import styled from 'styled-components'
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import copy from '../img/copy.svg';
import face from '../img/facebook.svg';
import insta from '../img/instagram.svg';

const Footer = () => {
    const [element, controls] = useScroll();
    return (
        <Foot variants={scrollReveal} ref={element} initial="hidden" animate={controls}>
            <Container>

                <Link id="logo" to="/"> <Logo src={logo} alt="logo home and more" /></Link>

                <article>
                    <h2>
                        Home & Family <br />
                        Stefanie Zinn
                    </h2>
                    <p>E-Mail: <a href="mailto:s.zinn@homeandfamily-mg.de">s.zinn@homeandfamily-mg.de</a></p>
                    <p>
                        0157 850 850 04

                    </p>
                    <p>
                        41236 Mönchengladbach
                    </p>
                </article>
                <div>
                    <a href="https://www.facebook.com/people/Stefanie-Zinn/100072818592116/?sk=about" target="_blank" ><img src={face} alt="facebook logo" /></a>
                    <a href="https://www.instagram.com/home_and_more_by_stefanie/" target="_blank" > <img src={insta} alt="insatgram logo" /></a>
                </div>

            </Container>
            <Copyright>
                <img src={copy} alt="" />
                <p>Copyright Home & Family by Stefanie.</p>
            </Copyright>

        </Foot >)
};

const Foot = styled.div`

background-color:#FAF4F4;


`;
const Container = styled.div`
width:80%;
display:grid;
grid-template-columns: 1fr 1fr 1fr;
margin:0 auto;
padding:5rem 0;
border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
@media (max-width: 1095px){
       display: flex;
       justify-content:center;
       align-items:center;
       flex-direction:column;
       
 };

h2{
    font-size: 2rem;
    line-height:3rem;
    text-align:center;
}
p{
    color:#707070;
    font-size:1.2rem;
    margin:0 ;
    padding:0;
    text-align:center;
    a{color:#707070;
    font-size:1.2rem;
    }
}
div{
    display: flex;
    width:100%;
    justify-content:flex-end;
    align-items: flex-start;
    
    @media (max-width: 1095px){
    
        justify-content:center;
}
    a{
        width:10%;
        
    }
    img{
        height:35px;
        margin-left:1rem;
        margin-top:3rem;
    }
}

`;
const Logo = styled(motion.img)`

width:20rem;

`;
const Copyright = styled.div`
width:77%;
margin:0 auto;
display: flex;
align-items: center;

p{
    font-size:.8rem;
    color:#707070;
    margin-left:1rem
}
@media (max-width: 1095px){
    
    justify-content:center;
}


`;

export default Footer;