import { createGlobalStyle } from "styled-components";
import com from '../fonts/Comfortaa-Regular.ttf';
import com2 from '../fonts/Comfortaa-Bold.ttf';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: "com";
    src: url(${com}) format("truetype")
  }
  @font-face {
    font-family: "com2";
    src: url(${com2}) format("truetype")
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html{
    @media (max-width: 1700px){
        font-size:75%;
    }
    
}
body{
    font-family: 'com';
    background: #fff;
   
    overflow-x: hidden;
    @media (min-width:2600px){
        width: 2600px;
        margin: 0 auto;
    }

    
}
button{
    font-weight: bold;
    font-size: 1.1.rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid #23d997;
    background: transparent;
    color: white;
    transition: all .5s ease;
    font-family: 'Inter', sans-serif;

    &:hover{
        background-color: #23d997;
        color: #42495B;
    }
}
h2{
        font-weight: lighter;
        font-size: 4rem;
    }
    h3{
        color: #42495B;
        font-size:2rem;
    }
    h4{
        font-weight: bold;
        font-size: 2rem;
        
    }
    a{
        font-size: 1.1rem;
    }
    span{
        font-weight: bold;
        color: #C19C9C;
        
    }
    p{
        padding: 1.5rem 0rem;
        color: #ccc;
        font-size: 1.4rem;
        line-height: 150%;
    }
`

export default GlobalStyle