import logo from '../img/logo.png';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';

const Nav = () => {
    const { pathname } = useLocation()
    return (
        <SyledNav>

            <Link id="logo" to="/"> <Logo src={logo} alt="logo home and more" /></Link>

            <ul>
                <li><Link style={{ color: '#000' }} to="/">Start</Link>
                    <Line transition={{ duration: 0.75 }} initial={{ width: "0%" }} animate={{ width: pathname === "/" ? "40%" : "0%" }} />
                </li>
                <li><Link to="/work">Dienstleistungen</Link>
                    <Line2 transition={{ duration: 0.75 }} initial={{ width: "0%" }} animate={{ width: pathname === "/work" ? "60%" : "0%" }} />
                </li>
                <li><Link to="/contact">Impressum/Datenschutz</Link>
                    <Line3 transition={{ duration: 0.75 }} initial={{ width: "0%" }} animate={{ width: pathname === "/contact" ? "70%" : "0%" }} />
                </li>
                <li><Link to="/karriere">Onlinekurse</Link>
                    <Line4 transition={{ duration: 0.75 }} initial={{ width: "0%" }} animate={{ width: pathname === "/karriere" ? "70%" : "0%" }} />
                </li>
                <li><Link to="/termine">Termine buchen</Link>
                    <Line5 transition={{ duration: 0.75 }} initial={{ width: "0%" }} animate={{ width: pathname === "/termine" ? "70%" : "0%" }} />
                </li>

            </ul>
        </SyledNav>

    );
};

const SyledNav = styled.nav`
position: absolute;
min-height: 20vh;
display: flex;
margin: auto;

align-items:flex-end;
padding: 1rem 5rem;


top:0;
z-index: 10;
@media (max-width: 1400px){
    display: none;
}
      
a{
    
    color: #fff;
    text-decoration: none;
    margin:0;
}
ul{
    display: flex;
    list-style: none;
    margin-left: -3rem;

}
li{
    padding-left: 9rem;
    position: relative;
    @media (min-width: 2268px){
      
        padding-left: 12rem;}
};

@media (max-width: 1300px){
      
       
       #logo{
           display:inline-block;
          
       }
       ul{
           padding: 2rem;
           margin-left: -8rem;
          
        
       }
       
    };
    @media (max-width: 1200px){
      
       
        ul{
           padding: 1rem;
           margin-left: -8rem;
          
        
       }
      
      
   };
`;







const Line = styled(motion.div)`
height: 0.1rem;
background: #666;
width: 0%;
position: absolute;
bottom: -80%;
left: 70%;
@media (max-width: 1000px){
   display: none}

`;

const Line2 = styled(motion.div)`
height: 0.1rem;
background: #fff;
width: 0%;
position: absolute;
bottom: -80%;
left: 45%;
@media (max-width: 1200px){
   display: none}

`;

const Line3 = styled(motion.div)`
height: 0.1rem;
background: #fff;
width: 0%;
position: absolute;
bottom: -80%;
left: 35%;
@media (max-width: 1200px){
   display: none;}
`;

const Line4 = styled(motion.div)`
height: 0.1rem;
background: #fff;
width: 0%;
position: absolute;
bottom: -80%;
left: 50%;
@media (max-width: 1200px){
   display: none;}
`;
const Line5 = styled(motion.div)`
height: 0.1rem;
background: #fff;
width: 0%;
position: absolute;
bottom: -80%;
left: 50%;
@media (max-width: 1200px){
   display: none;}
   

`;
const Logo = styled(motion.img)`

width:20rem;
`;


export default Nav;

