//Page components
import AboutSection from '../components/AboutSection'
import FaqSection from '../components/FaqSection'
import FaqSection2 from '../components/FaqSection2'
import VitaSection from '../components/VitaSection'
import ScrollTop from '../components/ScrollTop'
import ServicesSection from '../components/ServicesSection'
import ScrollToTop from "react-scroll-to-top";

//Animation
import { motion } from "framer-motion"
import { pageAnimation } from "../animation"


const AboutUs = () => {
    return (
        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">

            <AboutSection />
            <ServicesSection />
            <FaqSection />
            <FaqSection2 />
            <VitaSection />
            <ScrollTop />
            <ScrollToTop smooth width="25" height="25" viewBox="20 -50 500 240" style={{ paddingRight: "35px", height: "52px" }} />
        </motion.div>
    );
}

export default AboutUs;