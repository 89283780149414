

// import home1 from '../img/home1.png';
import help from '../img/help3.jpeg';
import blur from '../img/blur.png';
//Styled Components 
import styled from 'styled-components';
import { About, Description, Image, Hide } from '../styles'
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from './Wave';

const AboutSection = () => {
    // const titleAnim = {
    //     hidden: { opacity: 0 }, show: { opacity: 1, transition: { duration: 2 } }
    // }
    // const container = {
    //     hidden: { x: 100 }, show: { x: 0, transition: { duration: .75, ease: "easeOut" } }
    // }
    return (

        <About>

            <Description>
                <Blur variants={photoAnim} src={blur} alt="guy with a camera" />
                <Title>
                    <Hide>
                        <motion.h2 variants={titleAnim}>Willkommen bei </motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>Home & Family</motion.h2>
                    </Hide>

                    <Hide>
                        <motion.h2 variants={titleAnim}>Stefanie Zinn.</motion.h2>
                    </Hide>

                </Title>
                <Text variants={fade}>Bekommen Sie die Hilfe, die Sie im Alltag benötigen Maßgeschneiderte Alltagshilfe für Sie und Ihre Angehörigen</Text>


            </Description>
            <Image>
                <motion.img variants={photoAnim} src={help} alt="helfende Hände die sich festhalten" />

            </Image>
            {/* <Wave /> */}
        </About>
    );
};

// Styled Components

const Title = styled.div`
z-index: 5;
width: 150%;
color: #42495B;
@media (max-width: 800px){
        width: 90%;
        position: absolute;
        color:#fff;
        top:20%;
        left: 5%;
        
        
       
 }

`;
const Text = styled.p`
z-index: 5;
width: 190%;
color: #42495B;
@media (max-width: 800px){
        width: 80%;
        font-size:1.2rem;
       position: absolute;
       top:50%;
       left: 5%;
       color:#fff;
       
 }
`;
const Blur = styled.img`
position: absolute;
height: 77%;
width: 100%;
top: 20%;
left: -3%;
z-index:-1;
@media (max-width: 1120px){
      
    height: 50%;
          
       };
@media (max-width: 800px){
      
display: none;
    
 };
 
`;

export default AboutSection;