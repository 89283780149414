

// import home1 from '../img/home1.png';
import leistung from '../img/leistung.jpg';
import cook from '../img/cook.jpg';
import pfeil from '../img/arrow-right.svg';
//Styled Components 
import styled from 'styled-components';
import { About, Hide } from '../styles'
import com2 from '../fonts/Comfortaa-Bold.ttf';
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'
import { useState } from 'react';



const Card1 = () => {

    const [element, controls] = useScroll();
    const [isChecked, setIsChecked] = useState(false);
    const [isActive, setIsActive] = useState(false);
    // const titleAnim = {
    //     hidden: { opacity: 0 }, show: { opacity: 1, transition: { duration: 2 } }
    // }
    // const container = {
    //     hidden: { x: 100 }, show: { x: 0, transition: { duration: .75, ease: "easeOut" } }
    // }
    return (

        <Container variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <Cards onClick={() => setIsChecked(!isChecked)}>
                <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isChecked} />
                <Card className="card">
                    <Front>
                        <img className="layer" src={leistung} alt="" />

                        <Text>
                            <span>
                                <h3>
                                    Haushalts-
                </h3>
                                <h3>
                                    management
                </h3>

                            </span>

                        </Text>
                        <Pfeil src={pfeil} alt="" />
                    </Front>
                    <Back>
                        <h4>Haushaltsmanagement</h4>

                        <article>


                            <h5>
                                Erleben Sie unser Haushaltsmanagement, das sich ganz auf Ihre Bedürfnisse abstimmt. Wir bieten eine maßgeschneiderte Dienstleistung, die über die herkömmlichen Standards hinausgeht. Ob Organisation, Pflege oder spezielle Anforderungen – wir passen uns flexibel an Ihre Wünsche an. Ihr Zuhause wird nicht nur gepflegt, sondern auf persönliche Art und Weise betreut, um Ihnen den Raum für Entspannung zu schaffen, den Sie verdienen.
                            </h5>
                            <ul>
                                <li>Haushaltplanung und -führung</li>
                                <li>Kind- und seniorengerechte Zubereitung der Mahlzeiten</li>
                                <li>Beratung zu ausgewogener Ernährung</li>
                                <li>Unterstützung / Planung beim Einkauf</li>
                                <li>Budget-Einteilung</li>
                            </ul>
                        </article>

                        <Pfeil src={pfeil} alt="" />
                    </Back>
                </Card>
            </Cards>
            <Cards onClick={() => setIsActive(!isActive)}>
                <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isActive} />
                <Card className="card">
                    <Front>
                        <img className="layer" src={cook} alt="" />

                        <Text>
                            <span>
                                <h3>
                                    Seniorenbetreuung
                </h3>

                            </span>

                        </Text>
                        <Pfeil src={pfeil} alt="" />
                    </Front>
                    <Back>
                        <h4>Seniorenbetreuung</h4>
                        <article>


                            <h5>
                                Von Gesellschaftleisten und Vorlesen oder Hilfe beim wöchentlichen Einkauf bis hin zu Begleitung beim Spaziergang oder bei Arztbesuchen. Egal ob Krank, jung oder alt, wir begleiten Sie bei Besorgungen oder Erledigungen.
                            </h5>
                            <h5>Unsere Betreuung umfasst nicht nur praktische Hilfe im Haushalt, sondern auch emotionale Unterstützung und gemeinsame Aktivitäten, um soziale Kontakte zu fördern. Wir möchten, dass sich Ihre Angehörigen bei uns nicht nur versorgt, sondern auch geborgen und wertgeschätzt fühlen.

</h5>
                        </article>

                        <Pfeil src={pfeil} alt="" />
                    </Back>
                </Card>
            </Cards>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
display: flex;
justify-content: center;
width:80%;
margin: 15rem auto;
@media (max-width: 1095px){
      
      flex-direction:column;
      width:100%;
      margin: 1rem auto;
          
       };

`;

const Cards = styled.div`
display: flex;
justify-content: center;
perspective: 1000px;
	transform-style: preserve-3d;
	cursor: pointer;
    & :checked +.card{
        transform: rotateY(175deg);
       
    }
    

`;
const Check = styled.input`
display:none;

&:checked + .card{
    transform: rotateY(180deg);
}
`;

const Card = styled.div`

position: relative;
width: 80%;
height:50rem;
border-radius: 2rem;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
@media (min-width: 2300px){
    height:50rem;}

    @media (max-width: 1095px){
      margin: 5rem 0;
      height:59rem;
      
          
    };


`;
const Front = styled.div`
position: relative;
width: 100%;
height:100%;
backface-visibility: hidden;

.layer{
    width: 100%;
    object-fit: cover;
    border-radius: 2rem 2rem 0 0;
};

`;


const Pfeil = styled.img`
position: absolute;

width: 2rem;

bottom:5%;
right:8%;
`;


const Text = styled.div`
display:flex;
justify-content: space-between;

width:80%;

margin:3rem auto 0rem;
@media (max-width: 1095px){
        width:100%;
          
          margin:2rem auto 0rem;
          
    };

h3{
    font-size:2.5rem;
    @media (max-width: 1095px){
        
          font-size:2.2rem;
          
          
    };
}

p{
    font-size:1rem;
    color: #707070;
}
`;

const Back = styled.div`
@font-face {
    font-family: "com2";
    src: url(${com2}) format("truetype")
  }
  background-color:#FAF4F4;
  border-radius: 2rem;
position: absolute;
width: 100%;
height:100%;
top:0;
left:0;

backface-visibility: hidden;
    transform: rotateY(180deg);

h4{
    font-family: 'com2';
    font-size:2.5rem;
    width: 90%;
    margin: 3.5rem auto 2rem;
    @media (max-width: 2300px){
    font-size:2rem;
};
    @media (max-width: 1095px){
      
        font-size:1.9rem;
          
    };
}    

article{
    background:#fff;
    width:80%;
    margin: 2rem auto;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;
    @media (max-width: 1095px){
        margin: 1rem auto;
        width:90%;
        padding: .5rem 1.2rem;
}

    h5{
        font-size:1.2rem;
        line-height:2rem;
        margin: 2rem 0rem;
        @media (max-width: 2300px){
    font-size:1rem;}
    
    }
    @media (max-width: 1095px){
    font-size:.9rem;
    line-height:1.2rem;

}
    
    }
    ul{margin: 2rem 0rem;
    font-size:1.2rem;
    @media (max-width: 1095px){

    font-size:1rem;
    margin: 1rem 1rem;

}
    
    }
    li{
        margin-top:.7rem;
    }



`;


export default Card1;