

import styled from 'styled-components';



const Termine = () => {

    return (
        <div>
            <Container>
                <iframe src="https://outlook.office365.com/owa/calendar/HomeandFamily@HomeandFamily-mg.de/bookings/" width="100%" height="1900" frameborder="no" display="block" title="Termine buchen"></iframe>
            </Container>
            <Container2>
                <iframe src="https://outlook.office365.com/owa/calendar/HomeandFamily@HomeandFamily-mg.de/bookings/" width="100%" height="2300" frameborder="no" display="block" title="Termine buchen"></iframe>
            </Container2>
        </div>
    );
};

// Styled Components
const Container = styled.div`

width:100%;
padding: 2rem 0;
margin:1rem auto 1rem;
@media (max-width: 1024px){
    
    display: none;
}


`;
const Container2 = styled.div`

width:100%;
padding: 2rem 0;
margin:1rem auto 1rem;
@media (min-width: 1024px){
    
    display: none;
}


`;

export default Termine;