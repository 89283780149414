import styled from 'styled-components';
import { Link } from "react-router-dom";
import ScrollTop from '../components/ScrollTop'
import AboutSection from '../components/AboutSection'
import ScrollToTop from "react-scroll-to-top";


//Images

// import basic from '../img/Basic.png';
// import api1 from '../img/api-oben.png';
// import figma from '../img/figma.png';



//Animation
import { motion } from "framer-motion"
import { pageAnimation, fade, photoAnim, lineAnim, slider, sliderContainer } from "../animation";
import UnsereArbeit from '../components/UnsereArbeit';
import Card1 from '../components/Card1';
import Card2 from '../components/Card2';
import Vorteile from '../components/Vorteile';
import Termine from '../components/Termine';
// import { useScroll } from "../components/useScroll";


const OurWork = () => {


    return (
        <Work variants={pageAnimation} initial="hidden" animate="show" exit="exit">

            <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                <UnsereArbeit />
                <Card1 />
                <Card2 />
                <Vorteile />
                <ScrollTop />
                <ScrollToTop smooth width="25" height="25" viewBox="20 -50 500 240" style={{ paddingRight: "35px", height: "52px" }} />
            </motion.div>
        </Work>
    );
}

//mit motion.div können wir Framer auch bei styles mit styles components benutzen
const Work = styled(motion.div)`

`;







export default OurWork;