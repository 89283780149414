
import styled from 'styled-components';
import TermineHeader from '../components/TermineHeader';
import Termine from '../components/Termine';
import ScrollTop from '../components/ScrollTop';
import ScrollToTop from "react-scroll-to-top";
//Animation
import { motion } from "framer-motion"
import { pageAnimation } from "../animation"

const TermineBuchen = () => {



    return (


        <Details variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <TermineHeader />
            <Termine />
            <ScrollTop />
            <ScrollToTop smooth width="25" height="25" viewBox="20 -50 500 240" style={{ paddingRight: "35px", height: "52px" }} />
        </Details>


    );
};

const Details = styled(motion.div)`

`;


export default TermineBuchen;