import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import styled from 'styled-components';
import { motion } from "framer-motion";

import './Navbar.css';

function Navbar() {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: '#060b26' }}>
                <div className='navbar'>
                    <Link to='#' className='menu-bars'>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
                </div>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: '#f5f5f5' }}>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars'>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </IconContext.Provider>
            <Link id="logo" to="/"> <Logo src={logo} alt="logo home and more" /></Link>
        </>
    );
}


const Logo = styled(motion.img)`

width:20rem;
position: absolute;
left: 100px;
top:0;

@media (min-width: 1400px){
    
        display: none;
}
@media (max-width: 800px){
    
    width:15rem;
}
    
`;
export default Navbar;