
//Animation
import { motion } from "framer-motion"
import { pageAnimation, titleAnim } from "../animation"
import styled from 'styled-components';
import ScrollTop from '../components/ScrollTop'
import DatenschutzHeader from "../components/DatenschutzHeader";
import Impressum from "../components/Impressum";
import ScrollToTop from "react-scroll-to-top";

const ContactUs = () => {
    return (

        <ContactStyle exit="exit" variants={pageAnimation} initial="hidden" animate="show" >
            <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                <DatenschutzHeader />
                <Impressum />
                <ScrollTop />
                <ScrollToTop smooth width="25" height="25" viewBox="20 -50 500 240" style={{ paddingRight: "35px", height: "52px" }} />
            </motion.div>
        </ContactStyle>

    );
};

const ContactStyle = styled(motion.div)`

`;



export default ContactUs;