
import { motion } from "framer-motion";
import home from '../img/home.jpeg';
import layer from '../img/Rechteck.png';
// Import icons
import styled from 'styled-components'
//styles
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'

const ServicesSection = () => {
    const [element, controls] = useScroll();
    return (
        <Services variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <h2>
                Unsere<Divider> Mission</Divider>
            </h2>

            <Cards>
                <Image2>
                    <img className="layer" src={layer} alt="übergang" />
                    <img className="home" src={home} alt="zuhause sich wohl fühlen" />

                </Image2>
                <article>

                    <h3>
                        Sie liegen uns am Herzen
                </h3>
                    <p>
                        Bei uns stehen Sie im Mittelpunkt, denn Ihre Zufriedenheit ist unsere oberste Priorität. Wir legen großen Wert darauf, Ihnen nicht nur Produkte oder Dienstleistungen anzubieten, sondern ein ganzheitliches Erlebnis zu schaffen, das auf Ihre individuellen Bedürfnisse zugeschnitten ist.
                </p>
                    <p>Wir möchte mit Ihnen zusammen das Beste für Sie und Ihre
                    Angehörigen. Mit Empathie, Herzlichkeit , Ehrlichkeit und der
gewissen Leichtigkeit kommen wir an Ihr gewünschtes Ziel.</p>

                </article>



            </Cards>




        </Services>
    );
}


const Services = styled(motion.div)`
display: flex;
flex-direction: column;
width: 80%;
margin: 8rem auto;
@media (max-width: 1095px){
    
    margin: 4rem auto;
}

h2{
    padding-bottom: 5rem;
    text-decoration: underline;
    text-decoration-color: #666;
    text-decoration-thickness: 1px;
   text-underline-offset: 15px;
   text-align: right;
   font-size: 3rem;
   
   @media (max-width: 1095px){
    text-align: center;
    
    span{
        margin:0;
    }
}
}
p{
    width: 70%;
    color:#707070;
    font-size:1rem;
}

`;

const Cards = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
@media (max-width: 1095px){
    
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
}

article{
    display:flex;
    flex-direction: column;
    justify-content:center;
    position: relative;
    top:5%;
    
    @media (max-width: 1095px){
        h3{
            font-size:2rem;
            align-items:center;
            width:100%;
        }
        p{
            width:100%;
            font-size: 1.2rem;
        }
    align-items: center;
    justify-content:center;
    
    }
    img{
        position: absolute;
        left: -25rem;
        top:0;
        z-index:-10;
    }
}
    
    

`;

const Image2 = styled.div`
position: relative;
.layer{
    width: 180%;
    position: absolute;
    z-index:-1;
    left:20%;
    top: 15%;
    @media (max-width: 1095px){
    
        width: 100%;
        height:100%;
        left:0%;
    top: 50%;
    border-radius: 3rem;
    display:none;
}
}
.home{
    position: relative;
    width: 70%;
    border-radius: 3rem;
    z-index:-100;
    @media (max-width: 1095px){
    
    display:none;
}
}

`;
const Divider = styled.span`
margin:0;

`;

// const Card = styled.div`
// flex-basis: 20rem;
// .icon{
//     display: flex;
//     align-items: center;
//     h3{
//         margin-left: 1rem;
//         background: white;
//         color: black;
//         padding: 1rem;
//     }



// }
// `
export default ServicesSection;