


//Styled Components 
import styled from 'styled-components';
import { About, Hide } from '../styles'
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'


const Vorteile = () => {
    const [element, controls] = useScroll();
    return (

        <Container1 variants={scrollReveal} animate={controls} initial="hidden" ref={element}>

            <article>
                <h3>
                    Maßgeschneiderte Online-Kurse & Beratung für Ihr Zuhause
                </h3>
                <h4>Willkommen bei Home & Family – Ihrem exklusiven Dienstleister für maßgeschneiderte Online-Kurse und Beratung im Bereich Hauswirtschaft und Haushaltsmanagement. Als examinierter Dienstleister bieten wir einen ganzheitlichen Ansatz von Organisation und Planung bis hin zur generationenübergreifenden Betreuung.
</h4>
                <h4>Erfahren Sie, wie wir Ihnen einen einzigartigen Service bieten, der bei anderen ambulanten Dienstleistern nicht zu finden ist.</h4>

                <h3>Online-Kurse für mehr Familienharmonie</h3>

                <span>
                    <h4>1. Familienkommunikation im Chaos</h4>
                    <p>Der Kurs beinhaltet folgende Themen:</p>
                    <p>Erfahren Sie in diesem Kurs, wie Sie als überforderte Familie wieder zu einer harmonischen Kommunikation finden. Unsere Experten helfen Ihnen, Konflikte zu lösen und eine positive Gesprächskultur aufzubauen, damit Ihr Zuhause wieder zum Ort der Liebe wird.</p>

                    <p></p>
                </span>
                <span>
                    <h4>2. Finanzmeister im eigenen Haus</h4>
                    <p>Der Kurs beinhaltet folgende Themen:</p>

                    <p>Für diejenigen, die sich finanziell überfordert fühlen oder einfach ihre Budgetierung optimieren wollen: Unser Kurs zeigt praxisnahe Strategien, wie Sie Ihre Finanzen in den Griff bekommen und Ihre finanzielle Sicherheit stärken können.</p>

                    <p></p>
                </span>
                <span>
                    <h4>3. Haushaltsmanagement für Einsteiger</h4>
                    <p>Der Kurs beinhaltet folgende Themen:</p>

                    <p>Fühlen Sie sich von den Aufgaben im Haushalt überwältigt? In diesem Kurs lernen Sie Schritt für Schritt, wie Sie Ihren Haushalt organisieren und effizient managen können. Holen Sie sich Tipps von unseren Profis für mehr Zeit und Ruhe im Alltag.</p>
                    <p></p>
                </span>
                <span>
                    <h4>4. Gourmetküche ohne Budgetgrenzen</h4>
                    <p>Der Kurs beinhaltet folgende Themen:</p>

                    <p>Für diejenigen, die kulinarische Genüsse schätzen: Entdecken Sie in diesem Kochkurs, wie Sie auch mit üppigem Budget gesunde und exquisite Gerichte zaubern können. Ein Festmahl für Gaumen und Sinne!</p>
                    <p></p>
                </span>
                <span>
                    <h4>5. Generationenverbindend: Betreuung für Alle</h4>
                    <p>Der Kurs beinhaltet folgende Themen:</p>

                    <p>Unsere generationenübergreifende Betreuung ist nicht nur für Familien in schwierigen Situationen. Auch für diejenigen, die ihren Lieben eine exklusive Betreuung gönnen wollen, bieten wir individuelle Dienstleistungen an, die über herkömmliche Standards hinausgehen.
Erkunden Sie unsere Online-Kurse und finden Sie den passenden Weg zu mehr Harmonie und Wohlbefinden in Ihrer Familie – unabhängig von Ihrer aktuellen Lebenssituation.</p>
                    <p></p>
                </span>

                <h4>
                    Ich freue mich von Ihnen zu hören:
                </h4>
                <p>
                    s.zinn@ homeandfamily-mg.de
</p>



            </article>
        </Container1>
    );
};

// Styled Components
const Container1 = styled.div`
width:80%;
padding: 3rem 0;
margin:2rem auto 8rem;
@media (max-width: 500px){
      
    width:90%;
    margin:1rem auto 3rem;
          
       };
article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 6rem 2rem;
    @media (max-width: 500px){
      
        padding: 3rem 2rem;
            
         };

    h3{
        margin: 4rem 0rem 2rem;
        color: #C19C9C;
        @media (max-width: 500px){
      
      text-align:center;
      margin: 1rem 0rem 3rem;
      line-height:2.5rem;
          
       };
    }
    h4{text-align: center;
               font-size: 1.8rem;
               margin: 4rem 0rem 2rem;
               @media (max-width: 500px){
      
                font-size: 1.5rem;
                line-height:2.5rem;
                margin: 2rem 0rem 2rem;
          
       };
           }
    p{
        color:#707070;
        @media (max-width: 500px){
      
      font-size: 1.2rem;
    

};
       
    }
    span{
        display: flex;
        width:80%;
       flex-direction: column;
       align-items: flex-start;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        h4{
            color:#707070;
        }

        ul{
            margin: 2rem;
            
            li{
                font-size: 1.5rem;
                margin-bottom: 2rem;
                color:#707070;
                @media (max-width: 500px){
      
      font-size: 1.2rem;
    

};
            }
        }
    }

    .daten{
        width: 60%;
        list-style: none;
        li{
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
           
           h5{
               font-size: 1.5rem;
           }
          

            p{
                font-size: 1.5rem;
                color:#707070;
                margin: 0;
                padding: 0;
            }
        }
    }
};


`;



export default Vorteile;