
import styled from 'styled-components';
import KarriereHeader from '../components/KarriereHeader';
import KarriereBody from '../components/KarriereBody';
import ScrollTop from '../components/ScrollTop';
import ScrollToTop from "react-scroll-to-top";
//Animation
import { motion } from "framer-motion"
import { pageAnimation } from "../animation"

const ProjektDetail = () => {



    return (


        <Details variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <KarriereHeader />
            <KarriereBody />
            <ScrollTop />
            <ScrollToTop smooth width="25" height="25" viewBox="20 -50 500 240" style={{ paddingRight: "35px", height: "52px" }} />
        </Details>


    );
};

const Details = styled(motion.div)`

`;











export default ProjektDetail;