
import { motion } from "framer-motion";
import home from '../img/home.jpeg';
import layer from '../img/Rechteck.png';
// Import icons
import styled from 'styled-components'
//styles
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'

const ServicesSection = () => {
    const [element, controls] = useScroll();
    return (
        <Services variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <Container>
                <h2>
                    Wege der<Divider> Finanzierung</Divider>
                </h2>

                <Cards>
                    <Image2>
                        <img className="layer" src={layer} alt="übergang" />
                        <img className="home" src={home} alt="zuhause sich wohl fühlen" />

                    </Image2>
                    <article>

                        <h3>
                            ...und finanzieller Sorglosigkeit
                </h3>
                        <h4>Pflegeleistungen: Verrechnung mit Krankenkassen</h4>
                        <p>
                            Für Personen mit Pflegegrad bieten wir spezielle Leistungen an. Diese können bequem über die Krankenkasse abgerechnet werden. So möchten wir sicherstellen, dass Sie die bestmögliche Unterstützung erhalten, ohne sich um bürokratische Hürden kümmern zu müssen. Vertrauen Sie auf unsere Expertise und lassen Sie uns gemeinsam für Ihr Wohlbefinden sorgen.
                </p>

                    </article>



                </Cards>
            </Container>




        </Services>
    );
}


const Services = styled(motion.div)`
display: flex;
flex-direction: column;
width: 80%;
margin: 8rem auto;
@media (max-width: 1095px){
    
    margin: 4rem auto;
}

h2{
    padding-bottom: 5rem;
    text-decoration: underline;
    text-decoration-color: #666;
    text-decoration-thickness: 1px;
   text-underline-offset: 15px;
   text-align: right;
   font-size: 3rem;
   @media (max-width: 1095px){
    text-align: center;
    font-size: 2rem;
    
    span{
        margin:0;
    }
}
}

`;
const Container = styled.section`
display: flex;
flex-direction: column;
width: 80%;
margin: 8rem auto;
@media (max-width: 1095px){
        width:100%;
        margin: 2rem auto;
}
`;

const Cards = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
@media (max-width: 1095px){
        display:flex;
        flex-direction:column;
}

article{
    display:flex;
    flex-direction: column;
    justify-content:center;
    position: relative;
    top:5%;
    @media (max-width: 1095px){
        padding-left:0rem;
        align-items:center;
}
   
    h3{
    width: 100%;
    @media (max-width: 2070px){
        width: 120%;
        margin-bottom: .4rem;
        @media (max-width: 1095px){
            width: 100%;
            justify-content:center;
            margin: 2rem auto;
}
    }
}
h4{
    color:#000;
    font-size:1.3rem;
    padding: 1.5rem 0rem 1rem;
      width: 100%;
      @media (max-width: 1095px){
            width: 100%;
            font-size: 1.3rem;
            margin: 2rem auto;
            
}
}
   p{
    color:#707070;
    font-size:1rem;
    padding: 1.5rem 0rem 1.5rem;
      width: 80%;
      @media (max-width: 2070px){
        width: 120%;
        padding: .8rem 0rem;
        @media (max-width: 1095px){
            width: 100%;
            font-size: 1.2rem;
            margin: 2rem auto;
            
}
    }
   }
   h5{
       font-size: 1.2rem;
   }
   
  
}
`;

const Image2 = styled.div`
position: relative;
.layer{
    width: 180%;
    position: absolute;
    z-index:-1;
    left:20%;
    top: 15%;
    @media (max-width: 1095px){
    display:none;
}
}
.home{
    position: relative;
    width: 70%;
    border-radius: 3rem;
    z-index:-100;
    @media (max-width: 1095px){
        display:none;
}
}

`;
const Divider = styled.span`
margin:0;

`;
export default ServicesSection;